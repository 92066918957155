@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

:root {
  --font-family: 'Manrope', sans-serif;

  --gradient-text: linear-gradient(89.97deg, #BB5820 1.84%, #D8D084 150.67%);
  --gradient-bar: linear-gradient(103.22deg, #BB5820 -13.86%, #D8D084 120.55%);
  
  --color-bg: #ffffff;
  --color-footer : #663113;
  --color-blog: #ff0000;
  --color-text: #000000;
  --color-subtext: #BB5820;
}
